/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

@font-face {
  font-family: 'source-sans-pro';
  src: local('source-sans-pro'),
    url('./fonts/source-sans-pro.regular.ttf') format('truetype'),
    url('./fonts/source-sans-pro.light.ttf') format('truetype'),
    url('./fonts/source-sans-pro.semibold.ttf') format('truetype'),
    url('./fonts/source-sans-pro.bold.ttf') format('truetype'),
    url('./fonts/source-sans-pro.extralight.ttf') format('truetype');
}

html {
  font-size: 14px;
  height: 100%;
  overflow: hidden;
}

#root {
  height: 100%;
}

body {
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-family: 'source-sans-pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
